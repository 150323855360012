import {Component} from '@angular/core';
import {BaseComponent} from '@shared/lib/components/base.component';

@Component({
  selector: 'u-footer-cn',
  templateUrl: './footer-cn.component.html',
  styleUrls: ['./footer-cn.component.scss']
})
export class FooterCnComponent extends BaseComponent {
  src = 'footer-cn';
  map = {
    emailText: 'emailText',
    followText: 'followText',
    disclaimer1: 'disclaimer1',
    cookiesettings: 'cookiesettings',
    copyrights: [
      {
        linkUrl: 'copyrights.0.linkUrl',
        name: 'copyrights.0.name',
        image: 'copyrights.0.image'
      },
      {
        linkUrl: 'copyrights.1.linkUrl',
        name: 'copyrights.1.name',
      }
    ],
    meanInfos: [
      {
        iconName: 'meanInfos.0.iconName',
        image: 'meanInfos.0.image',
        name: 'meanInfos.0.name',
      },
      {
        iconName: 'meanInfos.1.iconName',
        image: 'meanInfos.1.image',
        name: 'meanInfos.1.name',
      },
      {
        iconName: 'meanInfos.3.iconName',
        image: 'meanInfos.3.image',
        name: 'meanInfos.3.name'
      },
      {
        iconName: 'meanInfos.2.iconName',
        image: 'meanInfos.2.image',
        hasMultipleImages: 'meanInfos.2.hasMultipleImages',
      }
    ],
    items: [
      {
        name: 'items.0.name',
        children: [
          {
            url: 'items.0.children.0.url',
            name: 'items.0.children.0.name',
            target: 'items.0.children.0.target'
          },
          {
            url: 'items.0.children.1.url',
            name: 'items.0.children.1.name',
            target: 'items.0.children.1.target'
          },
          {
            url: 'items.0.children.2.url',
            name: 'items.0.children.2.name',
            target: 'items.0.children.2.target'
          },
          {
            url: 'items.0.children.3.url',
            name: 'items.0.children.3.name',
            target: 'items.0.children.3.target'
          },
          {
            url: 'items.0.children.4.url',
            name: 'items.0.children.4.name',
            target: 'items.0.children.4.target'
          },
          {
            url: 'items.0.children.5.url',
            name: 'items.0.children.5.name',
            target: 'items.0.children.5.target'
          },
          {
            url: 'items.0.children.6.url',
            name: 'items.0.children.6.name',
            target: 'items.0.children.6.target'
          },
          {
            url: 'items.0.children.7.url',
            name: 'items.0.children.7.name',
            target: 'items.0.children.7.target'
          }
        ]
      },
      {
        name: 'items.1.name',
        children: [
          {
            url: 'items.1.children.0.url',
            name: 'items.1.children.0.name',
            target: 'items.1.children.0.target',
          },
          {
            url: 'items.1.children.1.url',
            name: 'items.1.children.1.name',
            target: 'items.1.children.1.target',
          },
          {
            url: 'items.1.children.2.url',
            name: 'items.1.children.2.name',
            target: 'items.1.children.2.target',
          },
          {
            url: 'items.1.children.3.url',
            name: 'items.1.children.3.name',
            target: 'items.1.children.3.target',
          },
          {
            url: 'items.1.children.4.url',
            name: 'items.1.children.4.name',
            target: 'items.1.children.4.target',
          },
          {
            url: 'items.1.children.5.url',
            name: 'items.1.children.5.name',
            target: 'items.1.children.5.target',
          }
        ]
      },
      {
        name: 'items.2.name',
        children: [
          {
            url: 'items.2.children.0.url',
            name: 'items.2.children.0.name',
            target: 'items.2.children.0.target',
          },
          {
            url: 'items.2.children.1.url',
            name: 'items.2.children.1.name',
            target: 'items.2.children.1.target',
          },
          {
            url: 'items.2.children.2.url',
            name: 'items.2.children.2.name',
            target: 'items.2.children.2.target'
          },
          {
            url: 'items.2.children.3.url',
            name: 'items.2.children.3.name',
            target: 'items.2.children.3.target'
          }
        ]
      }
    ]
  };
  public isCollapsed = [];

  isShow() {
    return false;
  }

}
