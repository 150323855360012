import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faAndroid, faWeibo, faWeixin } from '@fortawesome/free-brands-svg-icons';

const faWeixinMiniProgram = {
  prefix: 'fab',
  iconName: 'miniprogram',
  icon: [
    23,
    23,
    [],
    null,
    'M11.5 2a9.5 9.5 0 1 1 0 19 9.5 9.5 0 0 1 0-19Zm2.404 4.123c-1.69 0-3.078 1.29-3.213 2.933h-.01v4.626l-.001.044a1.576 1.576 0 0 1-1.584 1.522c-.994 0-1.693-.553-1.693-1.306 0-.804.384-1.207 1.387-1.497a.814.814 0 1 0-.458-1.564c-1.642.475-2.568 1.444-2.568 3.06 0 1.746 1.504 2.936 3.332 2.936 1.69 0 3.078-1.29 3.213-2.933h.01l.001-4.67a1.576 1.576 0 0 1 1.584-1.522c.994 0 1.693.553 1.693 1.306 0 .804-.384 1.207-1.387 1.497a.814.814 0 1 0 .458 1.564c1.642-.475 2.568-1.444 2.568-3.06 0-1.746-1.504-2.936-3.332-2.936Z',
  ],
} as any;

const BAOYING_FONTAWESOME_ICONS = [faWeixin, faWeibo, faAndroid, faWeixinMiniProgram];

@NgModule({
  imports: [CommonModule, FontAwesomeModule],
  exports: [FontAwesomeModule],
})
export class BaoyingFontAwesomeModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(...BAOYING_FONTAWESOME_ICONS);
  }
}
